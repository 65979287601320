import React, { type FC, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Spin } from 'antd';
import Cookies from 'js-cookie';

import API from 'api';
import { ButtonTypes } from 'components/Button/Button.types';
import StripeForm from 'components/StripeForm';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import ATSViewInvoice from 'modules/ATS/containers/ATSViewInvoice/ATSViewInvoice';
import { atsDucks } from 'modules/ATS/ducks';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import Tag from 'modules/Common/components/Tag';
import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { commonDucks } from 'modules/Common/ducks';
import {
	CreditsType,
	IInvoicesDetailedValues,
	InvoicesStatusColors,
	InvoicesStatusEnum,
	InvoicesStatusValuesEnum,
	ReceiptTypesEnum,
	InvoicesTypesValuesEnum,
} from 'modules/Common/types';
import { getDownloadPdfButtonShown } from 'modules/Common/utils/invoices';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, Routes, CookiesType, CurrencyType } from 'types';

import { Styled } from './AtsViewInvoicePage.styled';

type AtsViewInvoicePageProps = {
	currentInvoice: IInvoicesDetailedValues;
	countries: CountriesType;
	creditsFields: CreditsType[];
};
const redirectUrl = `${window.location.origin}/ats/finance`;

const AtsViewInvoicePage: FC<AtsViewInvoicePageProps> = ({
	currentInvoice,
	countries,
	creditsFields,
}) => {
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);

	const anchorRef = useRef<HTMLDivElement>(null);
	const [loadingRequest, setLoadingRequest] = useState(false);
	const { isUK } = countries || {};
	const currencySymbol = isUK ? CurrencyType.PoundSterling : CurrencyType.USDollar;

	const isSubscriptionInvoice = [
		InvoicesTypesValuesEnum.PremiumSubscritionManual,
		InvoicesTypesValuesEnum.PremiumSubscrition,
	].includes(currentInvoice?.invoiceType?.value);
	useScrollToTopOnMount(anchorRef);

	const [invoiceIntend, setInvoiceIntend] = useState(null);

	const handlePayInvoice = useCallback(async () => {
		setLoadingRequest(true);
		const res = await API.paymentsService.payForInvoice({ itemId: currentInvoice.id });
		setInvoiceIntend(() => res.client_secret);
		setLoadingRequest(() => false);
	}, [currentInvoice]);

	const statusColor =
		InvoicesStatusColors[currentInvoice?.invoiceStatus?.name as InvoicesStatusEnum];

	const isPaidFormShown =
		isUK &&
		!isImpersonate &&
		!isSubscriptionInvoice &&
		!!currentInvoice?.totalPrice &&
		currentInvoice?.invoiceStatus?.value !== InvoicesStatusValuesEnum.Paid;

	const isDownloadPdfButtonShown = getDownloadPdfButtonShown(
		!!currentInvoice && !!creditsFields,
		currentInvoice?.invoiceType?.value,
	);

	return (
		<Styled.Root ref={anchorRef}>
			<Styled.Head>
				<PageNavTitle
					title={currentInvoice?.btoReference}
					navigationLink={`${Routes.ATS}${Routes.Finance}`}
				/>
				<Styled.HeadRight>
					{currentInvoice?.invoiceStatus?.name === InvoicesStatusEnum.Paid && (
						<Tag
							color={String(statusColor)}
							style={{ height: '40px', lineHeight: '40px', fontSize: '16px' }}
						>
							{currentInvoice?.invoiceStatus?.name}
						</Tag>
					)}
					{isDownloadPdfButtonShown && (
						<Styled.PdfButtonWrapper>
							<PDFDownloadLink
								document={
									<FinancePdf
										contract={currentInvoice}
										creditsFields={creditsFields}
										type={ReceiptTypesEnum.Invoice}
									/>
								}
								fileName={`${currentInvoice?.btoReference}-invoice.pdf`}
							>
								{({ loading }) => {
									const buttonText = loading ? 'Loading document...' : 'Download PDF';

									return (
										<ThemedButton buttonType={ButtonTypes.secondary}>{buttonText}</ThemedButton>
									);
								}}
							</PDFDownloadLink>
						</Styled.PdfButtonWrapper>
					)}
				</Styled.HeadRight>
			</Styled.Head>
			<ATSViewInvoice />
			{isPaidFormShown && (
				<>
					{invoiceIntend ? (
						<StripeForm
							clientSecret={invoiceIntend}
							total={currentInvoice.totalPrice}
							currency={currencySymbol}
							redirectUrl={redirectUrl}
						/>
					) : (
						<ThemedButton
							onClick={handlePayInvoice}
							buttonType={ButtonTypes.secondary}
							disabled={loadingRequest}
							style={{ minWidth: '164px' }}
						>
							{loadingRequest ? <Spin /> : 'Pay for Invoice'}
						</ThemedButton>
					)}
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		currentInvoice: atsDucks.atsSelectors.getCurrentInvoice(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	}),
	{},
)(AtsViewInvoicePage);
