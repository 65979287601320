import { VideoQuestionnaireInfoType } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.types';
import { CountriesEnumIds, IOption } from 'types';

export enum AttachmentTypesEnum {
	CV = 'CV',
	CL = 'CL',
}

export interface IJobsApplied {
	client: number;
	jobId: number;
	title: string;
	status: string;
	candidateApplicationId?: string;
}

export interface ICandidateAttachments {
	id: 0;
	candidateApplication: string;
	name: string;
	file: string;
	bucket: string;
	attachmentType: AttachmentTypesEnum;
	created: string;
	candidate: string;
}

export interface ICandidateNotes {
	id: number;
	sender: {
		id: number;
		fullName: string;
	};
	created: string;
	text: string;
}

export interface ICandidatesData {
	id: number;
	name?: string;
	job?: number;
	phoneNumber?: string;
	email?: string;
	coverLetter?: string;
	text?: string;
	jobsApplied?: IJobsApplied[];
	candidateAttachments?: ICandidateAttachments[];
	candidateState?: IOption;
	candidate?: number;
	created?: string;
	videoQuestionnaireInfo?: VideoQuestionnaireInfoType | null;
	rank?: number;
	notes?: ICandidateNotes[];
	isNew: boolean;
}

export interface IUpdateCandidateRankProps {
	candidateAppId: string | number;
	rank: number;
	jobId?: string | number;
	atsReducerChanges?: boolean;
	cb?: () => void;
}

export type CandidateStatusType = (
	candidateIds: number[],
	status: string,
	jobId?: number | undefined,
	callback?: (ids: number[]) => void,
) => void;

export interface IFileProperties {
	referenceUuid: string;
	originalFileName: string;
}

export type JobDataType = {
	key?: string;
	id: number;
	jobId: number;
	title: string;
	client?: string;
	method?: string;
	location: string;
	created: string;
	updated: string;
	active: boolean;
	companyIntro: string;
	jobDescription: string;
	jobType: IOption;
	jobClass: JobClassesEnum;
	experienceLevel: string;
	applicationFormUrl: string;
	applicationEmail?: string;
	generatedEmail?: string;
	applicationMethod?: string;
	applicationFormAttachment?: { id: number; job: number; s3FileReference: IFileProperties };
	alternateDescription?: string;
	educationType: IOption;
	employmentType: IOption;
	candidatesStatistic: {
		id: number;
		title: string;
		amount: number;
	}[];
	interviewsNumber?: number;
	questionnairesNumber?: number;
	candidates: Partial<ICandidatesData>[];
	jobIndustry: number;
	dutiesAndResponsibilities: string;
	requirementsAndQualifications: string;
	benefits: string;
	startDate: string;
	endDate: string;
	expDate: string;
	salary?: string;
	linkedQuestionnaire?: string;
	clientName: string;
	applicationsNumber: string;
	messageRejection: string;
	restrictedUserIds: number[];
	isNew: boolean;
	reference?: string;
};

export type JobsListType = {
	applicationsNumber: number;
	client: string;
	id: number;
	interviewsNumber: number;
	jobClass: JobClassesEnum;
	method: string;
	questionnairesNumber: number;
	region: CountriesEnumIds;
	startDate: string;
	title: string;
};

export type CareerPageJobProps = {
	titleColor?: string;
	jobId?: number | string;
	clientId?: string;
	jobData?: JobDataType;
	clientBranding: {
		highlightColour: string;
	};
	getClientOpenBrandingRequested: (client: string) => void;
	getOpenJobByIdRequested: (jobId: number | string) => void;
	jobApplyState: {
		assessment?: object[];
		job?: number;
		fullName?: string;
		email?: string;
		phoneNumber?: string;
		uploadedCoverLetter?: string;
		uploadedCV?: string;
		coverLetter?: string;
	};
};

export interface ICandidatesSortedByStatus {
	status: IOption;
	options: ICandidatesData[];
}

export type JobsDataType = {
	data: JobDataType[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

export enum JobParamsEnum {
	RoleInfo = 'Role info',
	JobDescription = 'Job Description',
}

export const enum JobClassesEnum {
	PaidJob = 'PREMIUM',
	FreeJob = 'SELF_SERVICE',
	PendingJob = 'PENDING_BOOST',
}

// Related to PostJobTypeIdsEnum modules/Common/types/postJob
export enum JobClassesIdsEnum {
	PREMIUM = '1',
	SELF_SERVICE = '2',
	PENDING_BOOST = '3',
}

export enum JobRoleInfoEnum {
	ExperienceLevel = 'Experience Level',
	Education = 'Education',
	EmploymentType = 'Employment Type',
	Industry = 'Industry',
}

export enum JobDetailsViewTabIds {
	CV = '1',
	AssessmentForm = '2',
	CoverLetter = '3',
	VideoQuestionnaire = '4',
}

export enum JobDetailsViewTabValues {
	CV = 'CV',
	AssessmentForm = 'Assessment Form',
	CoverLetter = 'Cover Letter',
	VideoQuestionnaire = 'Video Questionnaire',
}

export type AssignCandidateToVacancyDatabaseResponseType = {
	createdCandidateApplications: {
		id: number;
		job: number;
		fullName: string;
		email: string;
		phoneNumber: string;
		coverLetter: null;
		uploadedCoverLetter: null;
		isNew: true;
		rank: null;
		candidateState: IOption;
		notes: ICandidateNotes[];
		includeToCandidateDatabase: boolean;
		uploadedCV: string;
	}[];
	failedToAssignJobs: {
		id: number;
		title: string;
	}[];
	alreadyAssignedJobs: {
		id: number;
		title: string;
	}[];
};

export type AssignCandidateToVacancyResponseType = Pick<
	AssignCandidateToVacancyDatabaseResponseType,
	'createdCandidateApplications' | 'failedToAssignJobs'
>;
